import { Box, DialogProps, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../../../../components/dialog';
import Ranking from '../../../../components/ranking';

export type TItem = { id: string; startIcon: ReactNode; title: string; value: number };

export const sizeRanges: Array<{ labelKey: string; min?: number; max?: number }> = [
  { labelKey: 'commons.communities.form.size_ranges.less_than_company', max: 50 },
  { labelKey: 'commons.communities.form.size_ranges.between_company', min: 51, max: 100 },
  { labelKey: 'commons.communities.form.size_ranges.between_company', min: 101, max: 500 },
  { labelKey: 'commons.communities.form.size_ranges.more_than_company', min: 501 },
];

function CompaniesDialog({
  companiesInArea,
  open,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose' | 'title'> & {
  companiesInArea: TItem[] | undefined;
  dialogTitle: string;
  onClose: () => void;
}): JSX.Element {
  const [sizeRangeIndex, setSizeRangeIndex] = useState(-1);
  const [items, setItems] = useState<TItem[]>();
  const { t } = useTranslation();

  useEffect(() => {
    const { min, max } =
      sizeRangeIndex > -1 ? sizeRanges[sizeRangeIndex] : { min: undefined, max: undefined };
    setItems(
      companiesInArea
        ?.filter(
          ({ value }) => (min === undefined || min <= value) && (max === undefined || max >= value),
        )
        .sort((a, b) => b.value - a.value),
    );
  }, [companiesInArea, sizeRangeIndex]);

  return (
    <Dialog
      confirmHidden
      fullHeight
      cancelTitle={t('commons.actions.close')}
      maxWidth="sm"
      onCancel={onClose}
      open={open}
      scroll="paper"
      title={
        <Box
          alignItems="center"
          component="span"
          display="flex"
          gap={2}
          justifyContent="space-between"
        >
          <span>{t('cycling-insights.community.home_work_journeys.companies_list.title')}</span>
          <FormControl margin="none" size="small">
            <Select
              onChange={({ target: { value } }) => {
                setSizeRangeIndex(typeof value === 'string' ? parseInt(value) : value);
              }}
              renderValue={(selected) => {
                if (selected === -1)
                  return (
                    <Typography color="#b6b6b6" component="span" fontSize="inherit">
                      {t('cycling-insights.community.home_work_journeys.companies_list.all_sizes')}
                    </Typography>
                  );

                const { labelKey, min, max } = sizeRanges[selected];
                return t(labelKey, { min, max });
              }}
              style={{ width: 300 }}
              value={sizeRangeIndex}
            >
              <MenuItem value={-1}>
                {t('cycling-insights.community.home_work_journeys.companies_list.all_sizes')}
              </MenuItem>
              {sizeRanges.map(({ labelKey, min, max }, index) => (
                <MenuItem key={index} value={index}>
                  {t(labelKey, { min, max })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      }
      {...props}
    >
      <Ranking
        disableProgression
        data={items}
        formatData={({ value }) => (
          <Typography minWidth={80} textAlign="end" variant="body2">
            {t('cycling-insights.community.home_work_journeys.companies_list.employees', {
              count: value,
            })}
          </Typography>
        )}
      />
    </Dialog>
  );
}

export default CompaniesDialog;
