import { Close } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormEvent, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

import Button from './button';

type TProps = Omit<DialogProps, 'title' | 'onClose'> & {
  children: ReactNode;
  cancelTitle?: ReactNode;
  closeHidden?: boolean;
  confirmDisabled?: boolean;
  confirmHidden?: boolean;
  confirmId?: string;
  confirmTitle?: ReactNode;
  dangerous?: boolean;
  dialogTitle: string;
  disableFullWidth?: boolean;
  disableContentPadding?: boolean;
  fullHeight?: boolean;
  hasCloseIcon?: boolean;
  isForm?: boolean;
  leftActions?: ReactNode;
  onCancel: () => void;
  onConfirm?: (event?: FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
  title?: ReactNode;
};

function Dialog({
  disableFullWidth,
  fullHeight,
  isForm,
  dialogTitle,
  children,
  loading,
  onCancel,
  onConfirm,
  ...props
}: TProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    dangerous,
    title,
    disableContentPadding,
    leftActions,
    cancelTitle,
    closeHidden,
    confirmHidden,
    confirmDisabled,
    confirmTitle,
    ...dialogProps
  } = props;

  return (
    <MuiDialog
      aria-labelledby={dialogTitle}
      disableEscapeKeyDown={loading}
      fullScreen={isSmallScreen}
      fullWidth={!disableFullWidth}
      onClose={(_, reason) => reason !== 'backdropClick' && onCancel()}
      sx={{
        '.MuiPaper-root': {
          borderRadius: 4,
          height: fullHeight ? 'calc(100% - 64px)' : undefined,
        },
      }}
      {...dialogProps}
    >
      {isForm ? (
        <form
          onSubmit={onConfirm}
          style={
            dialogProps.scroll
              ? { display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'auto' }
              : {}
          }
        >
          <Content
            isForm
            dialogTitle={dialogTitle}
            loading={loading}
            onCancel={onCancel}
            onConfirm={onConfirm}
            {...props}
          >
            {children}
          </Content>
        </form>
      ) : (
        <Content
          dialogTitle={dialogTitle}
          loading={loading}
          onCancel={onCancel}
          onConfirm={onConfirm}
          {...props}
        >
          {children}
        </Content>
      )}
    </MuiDialog>
  );
}

function Content({
  hasCloseIcon,
  isForm,
  dangerous,
  dialogTitle,
  title,
  disableContentPadding,
  children,
  leftActions,
  closeHidden,
  confirmHidden,
  confirmDisabled,
  cancelTitle,
  confirmId,
  confirmTitle,
  loading,
  onCancel,
  onConfirm,
}: TProps): JSX.Element {
  return (
    <>
      {title && (
        <DialogTitle color="#283859" fontSize="1.125rem" fontWeight="700" id={dialogTitle}>
          {title}
        </DialogTitle>
      )}
      {hasCloseIcon && (
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: '#212121',
          }}
        >
          <Close fontSize="medium" />
        </IconButton>
      )}
      <StyledContent sx={disableContentPadding ? { padding: 0 } : {}}>{children}</StyledContent>
      {(!closeHidden || (!confirmHidden && (isForm || onConfirm))) && (
        <DialogActions sx={{ padding: '16px 24px', gap: '8px' }}>
          {leftActions && (
            <>
              {leftActions}
              <Box flexGrow={1} />
            </>
          )}
          {!closeHidden && (
            <Button
              disabled={loading}
              onClick={() => onCancel()}
              size="large"
              type={isForm ? 'reset' : 'button'}
              variant="outlined"
            >
              {cancelTitle || <Trans i18nKey="commons.actions.cancel" />}
            </Button>
          )}
          {!confirmHidden && (isForm || onConfirm) && (
            <Button
              disableElevation
              color={dangerous ? undefined : 'primary'}
              dangerous={dangerous}
              disabled={loading || confirmDisabled}
              id={confirmId}
              onClick={isForm ? undefined : () => (onConfirm ? onConfirm() : undefined)}
              size="large"
              startIcon={loading && <CircularProgress color="inherit" size={16} thickness={4} />}
              type={isForm ? 'submit' : 'button'}
              variant="contained"
            >
              {confirmTitle || <Trans i18nKey="commons.actions.confirm" />}
            </Button>
          )}
        </DialogActions>
      )}
    </>
  );
}

const StyledContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;

export default Dialog;
