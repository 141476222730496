import { TReportTypeCode } from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import ReportDetails from '../../../../components/report-details';
import ReportsForm from '../../../../components/reports/form';
import useReports from '../../../../hooks/reports';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TParkingsPageContext } from '../../context';

function ParkingsReportsTab(): JSX.Element {
  const context = useOutletContext<TParkingsPageContext & TOutletContext>();
  const [typeCodes] = useState<TReportTypeCode[]>(['bikeParking']);
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const {
    period,
    header: { setPrevButtonClick, setTitle, setActions },
    parkings,
    setLoading,
  } = context;
  const { reportsTableRef, reports, selectedReportId, setReports } = parkings;
  const navigate = useNavigate();
  const { handleDownload } = useReports({ period, reportTypes, reports });

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../cycle-parkings'));
    setTitle(<Trans i18nKey="cycling-insights.reports.navigation.osm_cartographic_reports" />);

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="cycling-insights.reports.navigation.new_report" />}>
          <IconButton color="primary" component={Link} size="small" to="./new-report">
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.download" />}
        >
          <span>
            <IconButton
              color="primary"
              disabled={!reports}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
    };
  }, [reports]);

  return (
    <PageContentLayout
      leftPanel={
        <ReportsForm
          context={parkings}
          period={period}
          setLoading={setLoading}
          typeCodes={typeCodes}
        />
      }
      rightPanel={
        <ReportDetails
          isBindToOSM
          reloadTableReport={reportsTableRef.current?.reloadTableReport}
          reports={reports}
          selectedId={selectedReportId}
          setReports={setReports}
        />
      }
    />
  );
}

export default ParkingsReportsTab;
