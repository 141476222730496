import { GeoveloIcon, GeoveloTextIcon, useFileSaver } from '@geovelo-frontends/commons';
import { CalendarMonthOutlined, DirectionsBikeOutlined, SyncAlt } from '@mui/icons-material';
import {
  Box,
  DialogProps,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { cellToParent } from 'h3-js';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';
import NorthIcon from '../../../../components/icons/north';
import Map from '../../../../components/map';
import { TResolution, h3EdgeLengthsInMeter } from '../../../../hooks/map/h3';
import useOriginDestinationFlows from '../../../../hooks/map/origin-destination-flows';
import usePeriod from '../../../../hooks/period';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';

import { Map as MaplibreMap } from '!maplibre-gl';

function DownloadDialog({
  onClose,
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    period,
    originDestination: {
      h3Resolution,
      journeysType,
      currentRange,
      zones,
      externalZones,
      selectedH3Indices,
      flows,
      setH3Resolution,
    },
  } = context;
  const [map, setMap] = useState<MaplibreMap>();
  const [data, setData] = useState<{
    externalFromPartner: number;
    externalFromSelectedZones: number;
    internal: number;
    total: number;
  }>();
  const [downloading, setDownloading] = useState(false);
  const [downloadType, setDownloadType] = useState<'PNG' | 'XLSX'>('PNG');
  const printRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    map: { current: currentMap, baseLayer },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { downloadBlob, downloadXLSX } = useFileSaver();
  const { getTitle } = usePeriod();
  const { init: initLayers, clear: clearLayers } = useOriginDestinationFlows({ context, map });

  useEffect(() => {
    return () => {
      clearLayers();
      setMap(undefined);
    };
  }, [props.open]);

  useEffect(() => {
    const data = (flows || []).reduce(
      (
        res,
        {
          origin: _origin,
          destination: _destination,
          isInternalOrigin,
          isInternalDestination,
          count,
        },
      ) => {
        const origin =
          typeof h3Resolution === 'number' && h3Resolution < 9
            ? cellToParent(`${_origin}`, h3Resolution)
            : _origin;
        const destination =
          typeof h3Resolution === 'number' && h3Resolution < 9
            ? cellToParent(`${_destination}`, h3Resolution)
            : _destination;

        if (
          selectedH3Indices.length > 0 &&
          !selectedH3Indices.includes(origin) &&
          !selectedH3Indices.includes(destination)
        )
          return res;

        res.total += count;

        if (selectedH3Indices.length > 0) {
          if (selectedH3Indices.includes(origin) && selectedH3Indices.includes(destination))
            res.internal += count;
          else if (isInternalOrigin && isInternalDestination)
            res.externalFromSelectedZones += count;
          else res.externalFromPartner += count;
        } else {
          if (isInternalOrigin && isInternalDestination) res.internal += count;
          else res.externalFromPartner += count;
        }

        return res;
      },
      {
        internal: 0,
        externalFromPartner: 0,
        externalFromSelectedZones: 0,
        total: 0,
      },
    );

    setData(data);
  }, [h3Resolution, flows]);

  useEffect(() => {
    if (map && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [map, canvasRef.current]);

  async function handleDownload() {
    setDownloading(true);

    if (downloadType === 'PNG') {
      if (printRef.current) {
        const canvas = await html2canvas(printRef.current, { scale: 3, allowTaint: true });

        const blob = await new Promise<Blob>((resolve, reject) => {
          try {
            canvas.toBlob(
              (blob) => {
                if (!blob) throw new Error('no blob');
                else resolve(blob);
              },
              'image/png',
              1.0,
            );
          } catch (err) {
            reject(err);
          }
        });

        downloadBlob(`origin-destination.png`, blob);
      }
    } else if (flows && zones) {
      downloadXLSX(
        `${t('cycling-insights.usage.origin_destination.all_rides')
          .replace(/ /g, '_')
          .toLowerCase()}-${period.values.current.from.format(
          'YYYY-MM-DD',
        )}_${period.values.current.to.format('YYYY-MM-DD')}.xlsx`,
        [
          t('cycling-insights.usage.origin_destination.origin'),
          t('cycling-insights.usage.origin_destination.destination'),
          t('commons.stats_types.journeys'),
          t('cycling-insights.usage.origin_destination.internal_one'),
        ],
        flows.map(
          ({
            origin: _origin,
            isInternalOrigin,
            destination: _destination,
            isInternalDestination,
            count,
          }) => {
            const origin =
              (h3Resolution === 'cyclabilityZones' &&
                [...zones, ...(externalZones || [])].find(
                  ({ id }) => `cyclability-zone-${id}` === _origin,
                )?.name) ||
              _origin;
            const destination =
              (h3Resolution === 'cyclabilityZones' &&
                [...zones, ...(externalZones || [])].find(
                  ({ id }) => `cyclability-zone-${id}` === _destination,
                )?.name) ||
              _destination;

            return [
              origin,
              destination,
              count,
              isInternalOrigin && isInternalDestination ? 'True' : 'False',
            ];
          },
        ),
      );
    }

    setDownloading(false);
    onClose();
  }

  return (
    <>
      <Dialog
        disableContentPadding
        confirmTitle={t('commons.actions.download')}
        dialogTitle="facilities-download-dialog"
        loading={downloading}
        maxWidth="sm"
        onCancel={onClose}
        onConfirm={handleDownload}
        title={t('cycling-insights.usage.origin_destination.export_dialog.title')}
        {...props}
      >
        <Box display="flex" flexDirection="column" gap={2} padding={3}>
          <FormControl margin="none">
            <FormLabel component="legend" id="format-label" sx={{ fontSize: '0.875rem' }}>
              {t('cycling-insights.facilities.cyclability_zones.download_dialog.format')}
            </FormLabel>
            <Select
              labelId="format-label"
              name="format"
              onChange={({ target: { value } }) => setDownloadType(value as 'PNG' | 'XLSX')}
              size="small"
              value={downloadType}
            >
              <MenuItem value="PNG">PNG</MenuItem>
              <MenuItem value="XLSX">XLSX</MenuItem>
            </Select>
          </FormControl>
          {downloadType === 'XLSX' && (
            <FormControl margin="none">
              <FormLabel component="legend" id="level-label" sx={{ fontSize: '0.875rem' }}>
                {t('cycling-insights.usage.origin_destination.level_label')}
              </FormLabel>
              <Select
                labelId="level-label"
                name="level"
                onChange={({ target: { value } }) => setH3Resolution(value as TResolution)}
                size="small"
                value={h3Resolution}
              >
                {([7, 8, 9, 'cyclabilityZones'] as const).map((resolution) => {
                  if (resolution === 'cyclabilityZones') {
                    return (
                      <MenuItem key={resolution} value={resolution}>
                        {t('cycling-insights.usage.origin_destination.level_city')}
                      </MenuItem>
                    );
                  }

                  return (
                    <MenuItem key={resolution} value={resolution}>
                      {t('cycling-insights.usage.origin_destination.level', {
                        level: resolution,
                        length: h3EdgeLengthsInMeter[resolution],
                      })}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Box>
      </Dialog>
      {props.open && (
        <Box
          bgcolor="#fff"
          bottom={-10000}
          left={0}
          position="fixed"
          ref={printRef}
          width={1000}
          zIndex={-1}
        >
          <Box display="flex" flexDirection="column" gap={3} padding={3}>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="row"
              gap={3}
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography fontWeight={700} variant="h5">
                  {t('cycling-insights.usage.origin_destination.export_dialog.subtitle')}
                </Typography>
                <Box alignItems="center" display="flex" gap={3}>
                  <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                    <CalendarMonthOutlined color="secondary" fontSize="small" />
                    <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
                      {getTitle(period.values.current)}
                    </Typography>
                  </Box>
                  <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                    <DirectionsBikeOutlined color="secondary" fontSize="small" />
                    <Typography variant="body2">
                      {t(
                        `cycling-insights.usage.origin_destination.ranking.trips_types.${journeysType}`,
                        { context: 'long' },
                      )}
                    </Typography>
                  </Box>
                  <Box alignItems="center" display="flex" flexDirection="row" gap={1}>
                    <SyncAlt color="secondary" fontSize="small" />
                    <Typography variant="body2">
                      {t('commons.stats.passages_range', {
                        min: currentRange?.[0],
                        max: currentRange?.[1],
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box alignItems="center" display="flex" flexDirection="column">
                <GeoveloIcon style={{ height: 50, width: 50 }} />
                <GeoveloTextIcon style={{ height: 20, width: 'auto' }} />
              </Box>
            </Box>
            <Box height="952px" position="relative" width="952px">
              {props.open && (
                <StyledMap
                  disableInteractions
                  disableZoomControl
                  hasScaleControl
                  baseLayer={baseLayer}
                  bounds={currentMap?.getBounds()}
                  mapId="isochrones-map"
                  onInit={setMap}
                >
                  <StyledNorthIcon />
                </StyledMap>
              )}
              <StyledCanvas id="origin-destination-export-deck-canvas" ref={canvasRef} />
            </Box>
            {data && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={700} variant="subtitle2">
                  {t('cycling-insights.usage.origin_destination.export_dialog.caption')}
                </Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" flexDirection="row" height="16px" width="100%">
                    <Box
                      bgcolor="#3E7BDF"
                      flexShrink={0}
                      width={`${(100 * data.internal) / data.total}%`}
                    />
                    <Box bgcolor="#FFD12F" flexGrow={1} />
                  </Box>
                  <List dense disablePadding sx={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 24 }}>
                        <Box bgcolor="#3E7BDF" borderRadius={1} height={12} width={12} />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('cycling-insights.usage.origin_destination.internal_flows', {
                          count: data.internal,
                        })}
                        primaryTypographyProps={{ noWrap: true }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon sx={{ minWidth: 24 }}>
                        <Box bgcolor="#FFD12F" borderRadius={1} height={12} width={12} />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('cycling-insights.usage.origin_destination.external_flows', {
                          count: data.total - data.internal,
                        })}
                        primaryTypographyProps={{ noWrap: true }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            )}
            <Typography alignSelf="flex-end" marginTop={2} variant="body2">
              Sources: Les Contributeurs OpenStreetMap ; Geovelo - {moment().format('MMMM YYYY')}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

const StyledMap = styled(Map)`
  height: 100%;
  width: 100%;
  position: relative;
`;

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  cursor: default !important;
`;

const StyledNorthIcon = styled(NorthIcon)`
  z-index: 2;
  position: absolute;
  left: 8px;
  bottom: 8px;
  && {
    height: 36px;
    width: 36px;
  }
`;

export default DownloadDialog;
