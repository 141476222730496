import { Period } from '@geovelo-frontends/commons';
import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';

import Progression from './progression';

function StatCard({
  prevPeriod,
  label,
  value,
  prevValue,
  format,
  formatDiff,
}: {
  prevPeriod: Period;
  format?: (value: number) => string;
  formatDiff?: (value: number) => string;
  label: ReactNode;
  prevValue: number | undefined;
  value: number | undefined;
}): JSX.Element {
  return (
    <Card sx={{ alignSelf: 'flex-start', borderRadius: 4 }} variant="outlined">
      <CardContent sx={{ '&&': { paddingBottom: 2 } }}>
        <Typography color="textSecondary" variant="body2">
          {label}
        </Typography>
        <Typography variant="h5">
          {value !== undefined ? (
            format ? (
              format(value)
            ) : (
              value
            )
          ) : (
            <Skeleton variant="text" width={100} />
          )}
        </Typography>
        <Progression
          disableHighlight
          formatDiff={formatDiff}
          label={prevPeriod.from.format(prevPeriod.type === 'year' ? 'YYYY' : 'MMMM YYYY')}
          prevStat={prevValue}
          stat={value}
        />
      </CardContent>
    </Card>
  );
}

export default StatCard;
