import {
  CyclabilityZone,
  CyclabilityZoneService,
  Flow,
  OriginDestinationService,
  TH3Flow,
  TPeriod,
  prevMonth,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import centroid from '@turf/centroid';
import { cellToParent, cellsToMultiPolygon, polygonToCells } from 'h3-js';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import TabIntroduction from '../../../../components/tab-introduction';
import useOriginDestinationFlows from '../../../../hooks/map/origin-destination-flows';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import { toOriginDestinationInput } from '../../../../models/origin-destination-form';
import { IBicycleObservatoryPageContext } from '../../context';
import { TH3CellFeatureProps } from '../../models/origin-destination';

import OriginDestinationChart from './chart';
import JourneysType, { journeysTypePeriodProps } from './journeys-type';
import MainFlows from './main-flows';

function OriginDestinationForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    originDestination: {
      canvasRef,
      h3Resolution,
      journeysType,
      zones,
      externalZones,
      flows,
      setZones,
      setJourneysType,
      setExternalZones,
      setExternalH3Features,
      setBounds,
      setCurrentRange,
      setFlows,
      selectH3Indices,
    },
    setLoading,
  } = context;
  const [initialized, setInitialized] = useState(false);
  const [cyclabilityZonesFlows, setCyclabilityZonesFlows] = useState<Flow[]>();
  const [h3ResolutionType, setH3ResolutionType] = useState<'cyclabilityZones' | 'h3'>(
    h3Resolution === 'cyclabilityZones' ? 'cyclabilityZones' : 'h3',
  );
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const {
    map: { current: currentMap },
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    // initialized: layersInitialized,
    init: initLayers,
    clear: clearLayers,
  } = useOriginDestinationFlows({ context, map: currentMap });
  const { cancellablePromise: cancellableZonesPromise, cancelPromises: cancelZonesPromise } =
    useCancellablePromise();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    cancellablePromise: cancellableCyclabilityZonesFlowsPromise,
    cancelPromises: cancelCyclabilityZonesFlowsPromises,
  } = useCancellablePromise();
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    if (!initializedRef.current) {
      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;
    setInitialized(true);
    setLoading(true);

    return () => {
      cancelPromises();
      setFlows(undefined);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (
      initialized &&
      currentPartner?.administrativeLevel &&
      ['region', 'department', 'epci', 'city'].includes(currentPartner.administrativeLevel)
    )
      getZones();

    return () => {
      cancelZonesPromise();
    };
  }, [initialized]);

  useEffect(() => {
    if (initialized) {
      const newH3ResolutionType = h3Resolution === 'cyclabilityZones' ? 'cyclabilityZones' : 'h3';
      if (newH3ResolutionType !== h3ResolutionType) {
        cancelPromises();
        setFlows(undefined);
        setExternalH3Features(undefined);
        setH3ResolutionType(newH3ResolutionType);
      }
    }
  }, [initialized, h3Resolution]);

  useEffect(() => {
    return () => {
      clearLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (currentMap && canvasRef.current) {
      initLayers(canvasRef.current);
    }
  }, [currentMap, canvasRef.current]);

  useEffect(() => {
    if (zones) getCyclabilityZonesFlows(zones);

    return () => {
      cancelCyclabilityZonesFlowsPromises();
      setCyclabilityZonesFlows(undefined);
      setExternalZones(undefined);
    };
  }, [zones, period.values, journeysType]);

  useEffect(() => {
    if (zones) getData(zones);

    return () => {
      cancelPromises();
      setExternalH3Features(undefined);
      setFlows(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
    };
  }, [zones, period.values, h3ResolutionType, journeysType]);

  useEffect(() => {
    if (zones && cyclabilityZonesFlows && h3ResolutionType === 'cyclabilityZones') getData(zones);
  }, [cyclabilityZonesFlows]);

  useEffect(() => {
    if (flows) {
      let maxFlowsCount = 1;
      const flowsMap: { [key: string]: { [key: string]: number } } = {};
      flows?.forEach(({ origin: _origin, destination: _destination, count }) => {
        const origin =
          typeof h3Resolution === 'number' && h3Resolution < 9
            ? cellToParent(`${_origin}`, h3Resolution)
            : _origin;
        const destination =
          typeof h3Resolution === 'number' && h3Resolution < 9
            ? cellToParent(`${_destination}`, h3Resolution)
            : _destination;

        if (origin !== destination) {
          if (!flowsMap[origin]) flowsMap[origin] = {};
          if (!flowsMap[origin][destination]) flowsMap[origin][destination] = count;
          else flowsMap[origin][destination] += count;

          maxFlowsCount = Math.max(maxFlowsCount, flowsMap[origin][destination]);
        }
      });

      setBounds({ min: 0, max: maxFlowsCount });
      setCurrentRange([Math.min(maxFlowsCount, 5), maxFlowsCount]);
    }

    return () => {
      setBounds(undefined);
      setCurrentRange(undefined);
    };
  }, [flows, h3Resolution]);

  useEffect(() => {
    return () => {
      selectH3Indices([]);
    };
  }, [period.values, h3Resolution]);

  useEffect(() => {
    setLoading(!flows);
  }, [flows]);

  async function getZones() {
    if (!currentPartner) return;

    try {
      const { count, zones } = await cancellableZonesPromise(
        CyclabilityZoneService.getZones({
          administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
          partnerCode: currentPartner.code,
          page: 1,
          rowsPerPage: 100,
          query: '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
        }),
      );

      if (count > 100) {
        const nbPages = Math.ceil(count / 100);
        const otherPagesZones = (
          await cancellableZonesPromise(
            Promise.all(
              new Array(nbPages - 1).fill(null).map((_, index) =>
                CyclabilityZoneService.getZones({
                  administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                  partnerCode: currentPartner.code,
                  page: index + 2,
                  rowsPerPage: 100,
                  query:
                    '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
                }),
              ),
            ),
          )
        ).flatMap(({ zones }) => zones);

        zones.push(...otherPagesZones);
      }

      setZones(zones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getCyclabilityZonesFlows(zones: CyclabilityZone[]) {
    if (!currentPartner) return;

    const {
      values: { current: currentPeriod },
    } = period;

    try {
      const zoneIds = zones.map(({ id }) => id);

      const [flows, ...otherPeriodFlows] = await cancellableCyclabilityZonesFlowsPromise(
        Promise.all(
          journeysTypePeriodProps[journeysType].map((periodProps) =>
            OriginDestinationService.getFlows({
              period: currentPeriod.toIPeriod(),
              departureCyclabilityZoneIds: zoneIds,
              arrivalCyclabilityZoneIds: zoneIds,
              ...toOriginDestinationInput(periodProps),
            }),
          ),
        ),
      );

      otherPeriodFlows.forEach((otherFlows) => {
        otherFlows.forEach((otherFlow) => {
          const flow = flows.find(
            ({ origin, destination }) =>
              origin === otherFlow.origin && destination === otherFlow.destination,
          );
          if (flow) flow.count += otherFlow.count;
          else flows.push(otherFlow);
        });
      });

      const externalZonesIds = flows.reduce<number[]>((res, { origin, destination }) => {
        if (!zoneIds.includes(origin) && !res.includes(origin)) res.push(origin);
        if (!zoneIds.includes(destination) && !res.includes(destination)) res.push(destination);
        return res;
      }, []);

      const nbPages = Math.ceil(externalZonesIds.length / 100);
      const _externalZones = (
        await cancellableCyclabilityZonesFlowsPromise(
          Promise.all(
            new Array(nbPages).fill(null).map((_, pageIndex) =>
              CyclabilityZoneService.getZones({
                administrativeLevel: currentPartner.code === 'geovelo' ? 'REGION' : 'CITY',
                considerLivingStreets: true,
                ids: externalZonesIds.slice(pageIndex * 100, (pageIndex + 1) * 100),
                rowsPerPage: 100,
                query:
                  '{ id, code, reference, name, administrative_level, geo_polygon_simplified }',
              }),
            ),
          ),
        )
      ).flatMap(({ zones }) => zones);

      setCyclabilityZonesFlows(flows);
      setExternalZones(_externalZones);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });
      }
    }
  }

  async function getData(zones: CyclabilityZone[]) {
    try {
      const zoneIds = zones.map(({ id }) => id);

      const externalH3Features: Array<GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>> = [];
      let flows: TH3Flow[];

      if (h3ResolutionType === 'cyclabilityZones') {
        if (!cyclabilityZonesFlows) return;

        const _externalZonesIds: number[] = [];

        flows = cyclabilityZonesFlows.map(({ origin, destination, count }) => {
          const isInternalOrigin = zoneIds.includes(origin);
          const isInternalDestination = zoneIds.includes(destination);

          if (!isInternalOrigin) _externalZonesIds.push(origin);
          if (!isInternalDestination) _externalZonesIds.push(destination);

          return {
            origin: `cyclability-zone-${origin}`,
            destination: `cyclability-zone-${destination}`,
            count,
            isInternalOrigin,
            isInternalDestination,
          };
        });

        const externalZonesIds = [...new Set(_externalZonesIds)];

        const { zones: externalZones } = await CyclabilityZoneService.getZones({
          ids: externalZonesIds,
          administrativeLevel: 'CITY',
          page: 1,
          rowsPerPage: Math.min(externalZonesIds.length, 100),
          query: '{ id, code, name, administrative_level, geo_polygon_simplified }',
        });

        for (const { id, geometry, name } of externalZones) {
          if (geometry) {
            const h3Cells = polygonToCells(geometry.coordinates[0], 9);

            const polygon: GeoJSON.Polygon = {
              type: 'Polygon',
              coordinates: cellsToMultiPolygon(h3Cells, false)[0],
            };

            const [lng, lat] = centroid(polygon).geometry.coordinates;

            externalH3Features.push({
              type: 'Feature',
              geometry: polygon,
              properties: {
                center: { lat, lng },
                resolution: 'cyclabilityZones',
                h3Index: `cyclability-zone-${id}`,
                zoneId: `${id}`,
                name,
              },
            });
          }
        }
      } else {
        let otherPeriodFlows: TH3Flow[][];
        [flows, ...otherPeriodFlows] = await cancellablePromise(
          Promise.all(
            journeysTypePeriodProps[journeysType].map((periodProps) =>
              OriginDestinationService.getH3Flows({
                period: period.values.current.toIPeriod(),
                departureCyclabilityZoneIds: zoneIds,
                arrivalCyclabilityZoneIds: zoneIds,
                ...toOriginDestinationInput(periodProps),
              }),
            ),
          ),
        );

        otherPeriodFlows.forEach((otherFlows) => {
          otherFlows.forEach((otherFlow) => {
            const flow = flows.find(
              ({ origin, destination }) =>
                origin === otherFlow.origin && destination === otherFlow.destination,
            );
            if (flow) flow.count += otherFlow.count;
            else flows.push(otherFlow);
          });
        });
      }

      setExternalH3Features(externalH3Features);
      setFlows(flows);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.usage.origin_destination.server_error_zones'), {
          variant: 'error',
        });

        setExternalH3Features([]);
        setFlows([]);
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.origin_destination" />
      <Paper
        disablePadding
        header={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" justifyContent="flex-end">
              <PeriodForm
                disableComparison
                disablePadding
                disablePeriodTypeChange
                customPeriodTypes={customPeriodTypes}
                {...period}
              />
            </Box>
            <JourneysType journeysType={journeysType} setJourneysType={setJourneysType} />
          </Box>
        }
      >
        <Box paddingTop={3} paddingX={3}>
          <OriginDestinationChart {...context} />
        </Box>
        <MainFlows
          externalZones={externalZones}
          flows={cyclabilityZonesFlows}
          journeysType={journeysType}
          period={period}
          zones={zones}
        />
      </Paper>
    </Box>
  );
}

export default OriginDestinationForm;
