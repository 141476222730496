import {
  StatsService,
  Suggestion,
  TSuggestionResult,
  suggestionResultStatusMap,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import TabIntroduction from '../../../../components/tab-introduction';
import useFacilitiesSuggestions, {
  TSuggestionSectionProperties,
} from '../../../../hooks/map/facilities-suggestions';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';

import FacilitiesSuggestionsList from './list';

function FacilitiesSuggestionsContent(
  context: TCartographicDataPageContext & TOutletContext,
): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    facilitiesSuggestions: { data, selectedSuggestion, selectSuggestion, setData },
  } = context;
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    map: { current: currentMap },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const {
    initialized: mapInitialized,
    init,
    destroy,
    update,
  } = useFacilitiesSuggestions(currentMap);
  const [suggestionResults, setSuggestionResults] = useState<TSuggestionResult[]>();
  const { cancelPromises, cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) getSuggestions();

    return () => cancelPromises();
  }, [initialized]);

  useEffect(() => {
    if (currentMap) init();

    return () => destroy();
  }, [currentMap]);

  useEffect(() => {
    if (mapInitialized) filterSections();
  }, [mapInitialized, suggestionResults, sections]);

  async function getSuggestions() {
    if (!currentPartner) return;

    try {
      const results = await cancellablePromise(
        StatsService.getSuggestions({ partnerId: currentPartner.id }),
      );
      setData(results);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.stats.no_data'), {
          variant: 'error',
        });
      }
    }
  }

  async function handleRemove(suggestionId: number | null): Promise<void> {
    if (suggestionId === null || !currentPartner) return;

    setRemoveLoading(true);
    try {
      await cancellablePromise(
        StatsService.deleteSuggestion({ partnerId: currentPartner?.id, suggestionId }),
      );

      if (data) {
        const newSuggestions = [...data];
        newSuggestions.splice(
          newSuggestions.findIndex(({ id }) => suggestionId === id),
          1,
        );

        setData(newSuggestions);
        enqueueSnackbar(t('cycling-insights.qa.facilities_suggestions.deleted'));
        setRemoveLoading(false);
      }
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.qa.facilities_suggestions.not_deleted'));
        setRemoveLoading(false);
      }
    }
  }

  async function getSuggestionResults(suggestion: Suggestion) {
    if (!currentPartner) return;

    selectSuggestion(suggestion);

    try {
      const results = await cancellablePromise(
        StatsService.getSuggestionResults({
          partnerId: currentPartner.id,
          suggestionId: suggestion.id,
        }),
      );
      setSuggestionResults(results);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.qa.facilities_suggestions.error'), {
          variant: 'error',
        });
      }
    }
  }

  function filterSections() {
    if (suggestionResults && sections) {
      const features: GeoJSON.Feature<GeoJSON.LineString, TSuggestionSectionProperties>[] = [];
      sections.features.forEach(({ id, geometry, properties }) => {
        if (typeof id === 'number') {
          const suggestion = suggestionResults.find(
            ({ osmWaySectionId }) => osmWaySectionId === id,
          );
          if (suggestion) {
            const { isLeftSideSuggestion, status } = suggestion;
            const { color } = suggestionResultStatusMap[status];

            features.push(
              isLeftSideSuggestion
                ? {
                    type: 'Feature',
                    id: `${id}-backward`,
                    geometry: {
                      ...geometry,
                      coordinates: [...geometry.coordinates].reverse(),
                    },
                    properties: {
                      facility: properties.facilityLeft,
                      id,
                      roadType: properties.roadType,
                      wayName: properties.wayName,
                      color,
                    },
                  }
                : {
                    type: 'Feature',
                    id,
                    geometry: geometry,
                    properties: {
                      facility: properties.facilityRight,
                      id,
                      roadType: properties.roadType,
                      wayName: properties.wayName,
                      color,
                    },
                  },
            );
          }
        }
      });
      update({ type: 'FeatureCollection', features });
    } else update({ type: 'FeatureCollection', features: [] });
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="calc(100% - 32px)">
      <TabIntroduction title="cycling-insights.qa.introduction.facilities_suggestions" />
      <FacilitiesSuggestionsList
        data={data}
        loading={removeLoading}
        onClick={getSuggestionResults}
        onDelete={handleRemove}
        selectedSuggestion={selectedSuggestion}
        selectSuggestion={selectSuggestion}
      />
    </Box>
  );
}

export default FacilitiesSuggestionsContent;
