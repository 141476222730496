import {
  Report,
  ReportService,
  SectionStats,
  StatsService,
  TPeriod,
  prevMonth,
  roadTypesLabels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Place } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import illuFlux from '../../../../assets/images/illu-flux.png';
import illuNidDePoule from '../../../../assets/images/illu-nid-de-poule.png';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useCartographicReports from '../../../../hooks/map/cartographic-reports';
import useRoadsQuality from '../../../../hooks/map/roads-quality';
import usePeriod from '../../../../hooks/period';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import {
  getFilteredSections as _getFilteredSections,
  splitRoadsMinZoom,
} from '../../../../utils/sections';
import { TCartographicDataPageContext } from '../../context';

import RoadsQualityChart from './chart';

const maxPeriod = moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth;

function GeoveloRoadsQualityForm(
  context: TCartographicDataPageContext & TOutletContext,
): JSX.Element {
  const {
    defaultPeriods,
    period,
    roadsQuality: {
      data,
      selectedFacilities,
      bounds,
      currentRange,
      setData,
      setBounds,
      setCurrentRange,
    },
    setLoading,
  } = context;
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const [stats, setStats] = useState<{
    [key: number]: { current?: SectionStats; prev?: SectionStats };
  }>();
  const {
    map: { current: currentMap, zoom, potholesShowed },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    cancellablePromise: cancellableAnnualReportsPromise,
    cancelPromises: cancelAnnualReportsPromises,
  } = useCancellablePromise();
  const { cancellablePromise: cancellableReportsPromise, cancelPromises: cancelReportsPromises } =
    useCancellablePromise();
  const { getTitle: getPeriodTitle } = usePeriod();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useRoadsQuality(currentMap, period);
  const [reports, setReports] = useState<Report[]>();
  const { init: initReportsLayers } = useCartographicReports(currentMap, {
    cartographicReports: potholesShowed ? reports || [] : [],
  });
  const timeoutRef = useRef<NodeJS.Timeout>();
  const previousZoom = useRef<number>();
  const [currentYearPotholeReportsCount, setCurrentYearPotholeReportsCount] = useState<number>();
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    if (!initializedRef.current) {
      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;

    if (!sections) setLoading(true);
    getReports(true);

    return () => {
      cancelPromises();
      cancelReportsPromises();
      cancelAnnualReportsPromises();
      setData(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setLoading(false);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      initReportsLayers();
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    getStats();
    getReports();
  }, [period.values]);

  useEffect(() => {
    if (zoom === undefined) return;
    if (
      (zoom >= splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current < splitRoadsMinZoom) ||
      (zoom < splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current >= splitRoadsMinZoom)
    )
      getFilteredSections(true);
    previousZoom.current = zoom;
  }, [zoom]);

  useEffect(() => {
    if (sections && stats) setLoading(false);
  }, [sections, stats]);

  useEffect(() => {
    if (layersInitialized && sections && stats) getFilteredSections();
  }, [layersInitialized, sections, stats, selectedFacilities]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        updateLayers(data, { currentRange, primaryBounds: bounds });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [layersInitialized, data, currentRange]);

  async function getStats() {
    cancelPromises();
    setData(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);
    setStats(undefined);

    if (!currentPartner) return;

    const {
      values: { current: currentPeriod },
    } = period;

    setLoading(true);

    try {
      const [currentStats, currentFrequencyStats] = await cancellablePromise(
        Promise.all([
          StatsService.getSectionsQuality(currentPartner.id, currentPeriod.toIPeriod()),
          StatsService.getSectionsSpeedsAndFrequencies(
            currentPartner.id,
            currentPeriod.toIPeriod(),
            { timePeriod: 'all_day', dayPeriod: 'all' },
          ),
        ]),
      );

      const _stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};
      currentStats?.forEach((element) => {
        const frequencyStats = currentFrequencyStats.find(
          ({ sectionId }) => sectionId === element.sectionId,
        );
        const sectionStats: SectionStats = {
          sectionId: element.sectionId,
          frequencyBackward: frequencyStats?.frequencyBackward || 0,
          frequencyOnward: frequencyStats?.frequencyOnward || 0,
          roughnessesBackward: element.roughnessesBackward,
          roughnessesOnward: element.roughnessesOnward,
        };
        _stats[element.sectionId] = { current: sectionStats };
      });

      setStats(_stats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setBounds({ min: 0, max: 4 });
        setData(null);
      }
    }
  }

  async function getReports(annual?: boolean) {
    if (annual) {
      cancelAnnualReportsPromises();
      setCurrentYearPotholeReportsCount(undefined);

      try {
        const { count } = await cancellableAnnualReportsPromise(
          ReportService.getReports({
            period: {
              period: 'custom',
              from: moment().startOf('year'),
              to: moment(),
              unit: 'day',
            },
            typeCodes: ['pothole'],
            page: 1,
            rowsPerPage: 1,
            query: '{id}',
          }),
        );

        setCurrentYearPotholeReportsCount(count);
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
            variant: 'error',
          });
        }
      }
    } else {
      cancelReportsPromises();
      setReports(undefined);

      try {
        const _reports: Report[] = [];
        let hasNext = true;
        let page = 1;

        while (hasNext) {
          const { reports, next } = await cancellableReportsPromise(
            ReportService.getReports({
              period: period.values.current.toIPeriod(),
              typeCodes: ['pothole'],
              page: page++,
              rowsPerPage: 500,
              query:
                '{id, geo_point, creator{username}, created, updated, description, source, report_type_code, status, reviews, report_source, osm_note_id, photo, is_valid}',
            }),
          );

          _reports.push(...reports);
          hasNext = Boolean(next);
        }

        setReports(_reports);
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
            variant: 'error',
          });
        }
      }
    }
  }

  function getFilteredSections(disableRangeReset?: boolean) {
    if (!sections || !stats || zoom === undefined) return;

    const { filteredSections, bounds: _bounds } = _getFilteredSections({
      minFrequency: 1,
      maxBounds: 4,
      defaultMinRange: 0,
      sections,
      period,
      selectedFacilities,
      zoom,
      stats,
      primaryCriterion: 'roughness',
      bounds,
      currentRange,
    });

    setData(filteredSections);
    setBounds(_bounds);
    if (!disableRangeReset) setCurrentRange([0, 3]);
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.roads_quality" />

      <Paper
        header={
          <PeriodForm
            disableComparison
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={customPeriodTypes}
            maxPeriod={maxPeriod}
            {...period}
          />
        }
      >
        <RoadsQualityChart {...context} />
      </Paper>

      <Ranking
        disableProgression
        data={data?.features
          .sort((a, b) =>
            zoom && zoom >= splitRoadsMinZoom
              ? (b.properties.sectionFrequency || 0) - (a.properties.sectionFrequency || 0)
              : (b.properties.frequency || 0) - (a.properties.frequency || 0),
          )
          .filter(
            ({ id, properties: { roughness } }) =>
              roughness &&
              roughness < 2.5 &&
              (typeof id === 'number' ||
                (typeof id === 'string' &&
                  (id.indexOf('-') < 0 || (id.indexOf('-0') >= 0 && id.indexOf('backward') < 0)))),
          )
          .slice(0, 5)
          .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
            (
              res,
              { properties: { id: propertiesId, wayName, frequency, sectionFrequency, roadType } },
            ) => {
              if (zoom && zoom >= splitRoadsMinZoom) {
                res.push({
                  id: propertiesId,
                  title:
                    wayName ||
                    t(roadTypesLabels[roadType || 'unclassified']) +
                      ' - ' +
                      propertiesId.toString(),
                  value: sectionFrequency || 0,
                });
                return res;
              } else {
                res.push({
                  id: propertiesId,
                  title:
                    wayName ||
                    t(roadTypesLabels[roadType || 'unclassified']) +
                      ' - ' +
                      propertiesId.toString(),
                  value: frequency || 0,
                });
                return res;
              }
            },
            [],
          )}
        formatData={({ value }) => (
          <Typography fontWeight={600} minWidth={50} textAlign="end" variant="body2">
            <Trans count={value} i18nKey="commons.stats.passages" values={{ count: value }} />
          </Typography>
        )}
        onClick={(id) => {
          const feature = data?.features.find(({ properties }) => properties.id === id);
          const coordinates = feature?.geometry.coordinates;

          if (coordinates) {
            const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
            currentMap?.flyTo({
              center: { lat, lng },
              zoom: 18,
              animate: false,
            });
          }
        }}
        startIcon={<Place sx={{ color: '#E76685' }} />}
        title="cycling-insights.facilities.roads_quality.ranking.title"
      />
      <LinkCard
        description={
          <Trans i18nKey="cycling-insights.facilities.roads_quality.targeted_analysis.subtitle" />
        }
        icon={illuFlux}
        title={
          <Trans i18nKey="cycling-insights.facilities.roads_quality.targeted_analysis.title" />
        }
        to="../roads-quality-analysis"
      />
      <LinkCard
        description={
          <Trans i18nKey="cycling-insights.facilities.roads_quality.pothole_analysis.subtitle" />
        }
        icon={illuNidDePoule}
        title={
          currentYearPotholeReportsCount !== undefined ? (
            <Trans
              count={currentYearPotholeReportsCount}
              i18nKey="cycling-insights.facilities.roads_quality.pothole_analysis.title"
              values={{ count: currentYearPotholeReportsCount }}
            />
          ) : (
            <Skeleton variant="text" width={200} />
          )
        }
        to="../pothole-reports"
      />
      {/*<FacilitiesForm selectedFacilities={selectedFacilities} selectFacilities={selectFacilities} />
      <Box display="flex" flexDirection="column" flexShrink={0} gap={1} padding={2}>
        <Typography color="textSecondary" variant="caption">
          <Trans i18nKey="cycling-insights.facilities.roads_quality.form.filtering_by_quality" />
        </Typography>
        <Box alignItems="center" display="flex" gap={2}>
          <Typography>
            <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.from" />
          </Typography>
          <FormControl fullWidth>
            <Select
              onChange={({ target: { value } }) =>
                currentRange &&
                typeof value === 'number' &&
                setCurrentRange([value, currentRange[1]])
              }
              size="small"
              value={currentRange?.[0] || 0}
            >
              {defaultColors.map(({ labelKey, min, max }, index) => (
                <MenuItem
                  disabled={!currentRange || max === undefined || max > currentRange[1]}
                  key={index}
                  value={min}
                >
                  <Trans i18nKey={labelKey} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography>
            <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughnesses.to" />
          </Typography>
          <FormControl fullWidth>
            <Select
              onChange={({ target: { value } }) =>
                currentRange &&
                typeof value === 'number' &&
                setCurrentRange([currentRange[0], value])
              }
              size="small"
              value={currentRange?.[1] || 4}
            >
              {defaultColors.map(({ labelKey, min, max }, index) => (
                <MenuItem
                  disabled={!currentRange || min === undefined || min < currentRange[0]}
                  key={index}
                  value={max}
                >
                  <Trans i18nKey={labelKey} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box flexGrow={1} />
      <ColorLegend
        uniform
        bounds={bounds}
        colors={defaultColors}
        maxCaption={t(defaultColors[defaultColors.length - 1].labelKey || '')}
        minCaption={t(defaultColors[0].labelKey || '')}
        title={
          <Typography align="center" component="p" variant="caption">
            <Trans i18nKey="cycling-insights.facilities.roads_quality.form.roughness_legend" />
          </Typography>
        }
      />*/}
    </Box>
  );
}

export default GeoveloRoadsQualityForm;
