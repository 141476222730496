import { useAmplitudeTracker, useFileSaver } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import FluidityForm from './form';

function FluidityTab(): JSX.Element {
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const { t } = useTranslation();
  const {
    period,
    header: { setActions },
    averageSpeeds: { data, currentRange },
  } = context;
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="cycling-insights.facilities.actions.download" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!data}
            onClick={() => {
              handleDownload();
            }}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );

    return () => {
      setActions(undefined);
    };
  }, [data]);

  function handleDownload() {
    if (!data || !currentRange) return;

    trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Roads Use',
    });

    const {
      values: { current: currentPeriod, prev: prevPeriod },
      comparisonEnabled,
    } = period;
    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.bicycle_observatory.navigation.fluidity').replace(/ /g, '_').toLowerCase()}-${
        comparisonEnabled
          ? `${prevPeriod.from.format('YYYY-MM-DD')}_${prevPeriod.to.format('YYYY-MM-DD')}-`
          : ''
      }${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { frequency } }) =>
                  frequency && frequency >= min && frequency <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return <PageContentLayout leftPanel={<FluidityForm {...context} />} />;
}

export default FluidityTab;
