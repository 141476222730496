import { TReportTypeCode } from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../../app/context';
import ReportDetails from '../../../../../components/report-details';
import ReportsForm from '../../../../../components/reports/form';
import useReports from '../../../../../hooks/reports';
import { TOutletContext } from '../../../../../layouts/page/container';
import PageContentLayout from '../../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../../context';

function PotholeReportsTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const [typeCodes] = useState<TReportTypeCode[]>(['pothole']);
  const {
    report: { types: reportTypes },
  } = useContext(AppContext);
  const {
    period,
    header: { setPrevButtonClick, setTitle, setActions },
    potholes,
    setLoading,
  } = context;
  const { reports, selectedReportId, reportsTableRef, setReports } = potholes;
  const navigate = useNavigate();
  const { handleDownload } = useReports({ period, reportTypes, reports });

  useEffect(() => {
    setTitle(undefined);
    setPrevButtonClick(() => () => navigate('../roads-quality-new'));

    return () => {
      setPrevButtonClick(undefined);
    };
  }, []);

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={<Trans i18nKey="cycling-insights.reports.navigation.new_report" />}>
          <IconButton color="primary" component={Link} size="small" to="./new-report">
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<Trans i18nKey="cycling-insights.reports.osm_cartographic_reports.download" />}
        >
          <span>
            <IconButton
              color="primary"
              disabled={!reports}
              onClick={() => handleDownload()}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );

    return () => {
      setActions(undefined);
    };
  }, [reports]);

  return (
    <PageContentLayout
      leftPanel={
        <ReportsForm
          context={potholes}
          introductionKey="commons.navigation.pothole_reports_description"
          period={period}
          setLoading={setLoading}
          typeCodes={typeCodes}
        />
      }
      rightPanel={
        <ReportDetails
          hasActions
          reloadTableReport={reportsTableRef.current?.reloadTableReport}
          reports={reports}
          selectedId={selectedReportId}
          setReports={setReports}
        />
      }
    />
  );
}

export default PotholeReportsTab;
