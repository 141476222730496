import {
  Period,
  StatsService,
  currentMonth,
  prevMonth,
  roadTypesLabels,
  useAmplitudeTracker,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { Close, InfoOutlined, Place } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import FacilitiesForm from '../../../../components/form/facilities';
import PeriodForm from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useRoadsUses, {
  colors as _colors,
  colorsComparison,
} from '../../../../hooks/map/roads-uses';
import useQueryParams from '../../../../hooks/query-params';
import useSectionsStats from '../../../../hooks/sections-stats';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';
import FrequenciesChart from '../roads-use-new/chart';

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

const maxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function RoadsUseAnalysisForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    header: { setTitle, setPrevButtonClick, setActions },
    period,
    roadsUse,
    loading,
    setLoading,
  } = context;
  const {
    data,
    distancesByFacilities,
    bounds,
    currentRange,
    selectedFacilities,
    selectFacilities,
    setDistancesByFacilities,
    setPrevDistancesByFacilities,
    setQuartiles,
  } = roadsUse;
  const [lastMonthDistance, setLastMonthDistance] = useState<{
    normal: number;
    extrapolated: number;
  }>();
  const [lastYearDistance, setLastYearDistance] = useState<{
    normal: number;
    extrapolated: number;
  }>();
  const lastYear = useRef<Period>(period.values.current.clone());
  const lastMonth = useRef<Period>(period.values.current.clone());
  const [automaticReloadDisabled, disableAutomaticReload] = useState<boolean>(true);
  const {
    map: { current: currentMap, baseLayer },
    partner: { current: currentPartner, cyclabilityZones },
    actions: { getPartnerCyclabilityZones },
  } = useContext(AppContext);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useRoadsUses(currentMap, period);
  const { timeoutRef } = useSectionsStats({
    hasChartComparison: true,
    automaticReloadDisabled,
    primaryCriterion: 'frequency',
    initialized: layersInitialized,
    period,
    ...roadsUse,
    setLoading,
  });
  const [isSetting, setting] = useState<boolean>(true);
  const { downloadBlob } = useFileSaver();
  const { trackEvent: _trackEvent } = useAmplitudeTracker();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    if (!initializedRef.current) {
      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    lastYear.current = period.values.current.clone();
    lastYear.current.from.add(-1, 'year');
    lastYear.current.to.add(-1, 'year');
    lastMonth.current = period.values.current.clone();
    lastMonth.current.from.add(-1, 'month');
    lastMonth.current.to.add(-1, 'month').endOf('month');
  }, [period.values.current, currentPartner]);

  useEffect(() => {
    if (currentPartner && !cyclabilityZones) getPartnerCyclabilityZones(currentPartner);
  }, [currentPartner]);

  useEffect(() => {
    if (isSetting) {
      setPrevButtonClick(() => () => navigate('../roads-use'));
      setTitle(
        <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.roads_use_analysis" />,
      );
      setActions(undefined);
    } else {
      setPrevButtonClick(undefined);
      setTitle(<Trans i18nKey="commons.result" />);
      setActions(
        <Tooltip title={<Trans i18nKey="commons.actions.go_back" />}>
          <IconButton color="primary" component={Link} size="small" to="./roads-use">
            <Close />
          </IconButton>
        </Tooltip>,
      );
    }
  }, [isSetting]);

  useEffect(() => {
    if (currentMap) initLayers();

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    const { comparisonEnabled } = period;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        let _quartiles: number[] | undefined;

        if (!comparisonEnabled && data.features.length > 0) {
          const { min, max } = bounds;
          const speeds = data.features
            .map(({ properties: { frequency } }) => frequency)
            .filter((frequency) => frequency && frequency >= min && frequency <= max)
            .sort((a, b) => (a && b ? a - b : 1));

          _quartiles = [
            ...new Set([
              min,
              ..._colors
                .slice(0, Math.min(_colors.length - 1, speeds.length - 1))
                .map((_, index) =>
                  Math.round(
                    speeds[Math.round(speeds.length * ((index + 1) / _colors.length))] || 0,
                  ),
                ),
              max,
            ]),
          ];

          setQuartiles?.(_quartiles);
        }

        const colors = comparisonEnabled
          ? colorsComparison
          : baseLayer === 'dark'
            ? _colors
            : [..._colors].reverse();
        updateLayers(data, {
          colors: _quartiles
            ? colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
                value,
                min: _quartiles?.[index],
                max: _quartiles?.[index + 1],
              }))
            : colors,
          comparisonEnabled,
          currentRange,
          primaryBounds: bounds,
          secondaryBounds: bounds,
        });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          comparisonEnabled,
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds: { min: 0, max: 1 },
        },
      );
    }
  }, [baseLayer, layersInitialized, data, currentRange]);

  function resetFilters() {
    period.setValues({
      current: currentMonth,
      prev: prevMonth,
      timePeriod: 'all_day',
      dayPeriod: 'all',
    });
    period.enableComparison?.(false);
    selectFacilities([
      'cycleway',
      'lane',
      'greenway',
      'opposite',
      'sharebusway',
      'mixedfacilities',
      'other',
      'none',
    ]);
  }

  async function getDistancesTravelled() {
    setDistancesByFacilities?.(undefined);
    setLastMonthDistance(undefined);
    setLastYearDistance(undefined);

    if (!currentPartner) return;

    try {
      if (period.comparisonEnabled) {
        const [currentDistances, prevDistances] = await Promise.all([
          StatsService.getDistancesTravelled(currentPartner.id, period.values.current.toIPeriod()),
          StatsService.getDistancesTravelled(currentPartner.id, period.values.prev.toIPeriod()),
        ]);
        setDistancesByFacilities?.(currentDistances);
        setPrevDistancesByFacilities?.(prevDistances);
      } else {
        const [currentDistances, lastMonthDistances, lastYearDistances] = await Promise.all([
          StatsService.getDistancesTravelled(currentPartner.id, period.values.current.toIPeriod()),
          StatsService.getDistancesTravelled(currentPartner.id, lastMonth.current.toIPeriod()),
          StatsService.getDistancesTravelled(currentPartner.id, lastYear.current.toIPeriod()),
        ]);
        setDistancesByFacilities?.(currentDistances);
        setLastMonthDistance({
          extrapolated: lastMonthDistances.allExtrapolated,
          normal: lastMonthDistances.all,
        });
        setLastYearDistance({
          extrapolated: lastYearDistances.allExtrapolated,
          normal: lastYearDistances.all,
        });
      }
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.roads_use.server_error'), {
        variant: 'error',
      });
    }
  }

  function handleDownload() {
    if (!data || !currentRange) return;

    _trackEvent('File Downloaded', {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      file: 'Roads Use analysis',
    });

    const {
      values: { current: currentPeriod, prev: prevPeriod },
      comparisonEnabled,
    } = period;
    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.usage.navigation.road_use').replace(/ /g, '_').toLowerCase()}-${
        comparisonEnabled
          ? `${prevPeriod.from.format('YYYY-MM-DD')}_${prevPeriod.to.format('YYYY-MM-DD')}-`
          : ''
      }${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { frequency } }) =>
                  frequency && frequency >= min && frequency <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={4}
        justifyContent="space-between"
        minHeight="100%"
      >
        {isSetting ? (
          <>
            <Box display="flex" flexDirection="column" gap={6}>
              <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.analysis" />
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={600} variant="subtitle1">
                  <Trans i18nKey="commons.period" />
                </Typography>
                <PeriodForm
                  disablePadding
                  enableCustomComparison
                  enableDayPeriods
                  enableTimePeriods
                  maxPeriod={maxPeriod}
                  minPeriod={minPeriod}
                  {...period}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight={600} variant="subtitle1">
                  <Trans i18nKey="commons.facilities_types" />
                </Typography>
                <FacilitiesForm
                  selectedFacilities={selectedFacilities}
                  selectFacilities={selectFacilities}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box
                alignItems="center"
                bgcolor="#FFF4D8"
                borderRadius={2}
                display="flex"
                gap="12px"
                padding={2}
              >
                <InfoOutlined fontSize="small" sx={{ color: '#3F2813' }} />
                <Typography color="#3F2813" fontSize="0.75rem" variant="body2">
                  <Trans i18nKey="cycling-insights.bicycle_observatory.long_analysis" />
                </Typography>
              </Box>
              <Box display="flex" gap={2} justifyContent="flex-end">
                <StyledButton
                  disableElevation
                  color="primary"
                  onClick={resetFilters}
                  variant="outlined"
                >
                  <Trans i18nKey="commons.actions.reset" />
                </StyledButton>
                <StyledButton
                  disableElevation
                  color="primary"
                  onClick={() => {
                    disableAutomaticReload(false);
                    getDistancesTravelled();
                    setting(false);
                  }}
                  variant="contained"
                >
                  <Trans i18nKey="commons.actions.analyze" />
                </StyledButton>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" flexDirection="column" gap={3}>
              <Paper
                header={
                  <PeriodForm
                    disablePadding
                    disablePeriodTypeChange
                    maxPeriod={maxPeriod}
                    minPeriod={minPeriod}
                    {...period}
                  />
                }
              >
                <FrequenciesChart
                  lastMonthDistance={lastMonthDistance}
                  lastYearDistance={lastYearDistance}
                  {...context}
                />
              </Paper>
              <Ranking
                data={
                  !!data && data.features.length > 0
                    ? data.features
                        .sort((a, b) =>
                          a.properties.sectionDistanceTraveled !== undefined &&
                          b.properties.sectionDistanceTraveled !== undefined
                            ? (b.properties.sectionDistanceTraveled || 0) -
                              (a.properties.sectionDistanceTraveled || 0)
                            : (b.properties.distanceTraveled || 0) -
                              (a.properties.distanceTraveled || 0),
                        )
                        .slice(
                          0,
                          data.features[0].properties.sectionDistanceTraveled !== undefined
                            ? 10
                            : 5,
                        )
                        .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
                          (
                            res,
                            {
                              properties: {
                                id,
                                wayName,
                                distanceTraveled,
                                roadType,
                                sectionDistanceTraveled,
                              },
                            },
                            index,
                          ) => {
                            if (data.features[0].properties.sectionDistanceTraveled !== undefined) {
                              if (index % 2 === 0)
                                res.push({
                                  id,
                                  title:
                                    wayName ||
                                    t(roadTypesLabels[roadType || 'unclassified']) +
                                      ' - ' +
                                      id.toString(),
                                  value: sectionDistanceTraveled || 0,
                                });
                              return res;
                            } else {
                              res.push({
                                id,
                                title:
                                  wayName ||
                                  t(roadTypesLabels[roadType || 'unclassified']) +
                                    ' - ' +
                                    id.toString(),
                                value: distanceTraveled || 0,
                              });
                              return res;
                            }
                          },
                          [],
                        )
                    : undefined
                }
                formatProgress={(value) =>
                  distancesByFacilities?.all
                    ? `${((value / distancesByFacilities?.all) * 100).toFixed(2)} %`
                    : ''
                }
                onClick={(id) => {
                  const coordinates = data?.features.find(({ properties }) => properties.id === id)
                    ?.geometry.coordinates;

                  if (coordinates) {
                    const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
                    currentMap?.flyTo({
                      center: { lat, lng },
                      zoom: 18,
                      animate: false,
                    });
                  }
                }}
                startIcon={<Place sx={{ color: '#E76685' }} />}
                subtitle={
                  <Typography marginBottom={2} marginTop={1} variant="body2">
                    <Trans i18nKey="cycling-insights.usage.roads_use.ranking.subtitle" />
                  </Typography>
                }
                title="cycling-insights.usage.roads_use.ranking.title"
              />
            </Box>
            <Box display="flex" gap={2} justifyContent="flex-end">
              <StyledButton
                disableElevation
                color="primary"
                disabled={loading}
                onClick={() => handleDownload()}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.download" />
              </StyledButton>
              <StyledButton
                disableElevation
                color="primary"
                disabled={loading}
                onClick={() => {
                  disableAutomaticReload(true);
                  setting(true);
                }}
                variant="contained"
              >
                <Trans i18nKey="commons.actions.restart_analyze" />
              </StyledButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 54px;
  && {
    padding: 0 24px;
    font-weight: 700;
  }
`;

export default RoadsUseAnalysisForm;
