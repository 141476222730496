import { Challenge, FMDConfig, Ride, WidgetConfig } from '@geovelo-frontends/commons';
import { useState } from 'react';

import PageContainerLayout from '../../layouts/page/container';
import { IDashboardPage } from '../dashboard-page';

import AnimationTab from './components/animation';
import ChallengeDetails from './components/challenge-details';
import ChallengeUpdate from './components/challenge-update';
import ChallengesTab from './components/challenges';
import ChallengesFormTab from './components/challenges-form';
import HomeWorkJourneysTab from './components/home-work-journeys';
import MembersTab from './components/members';
import NewsFeedTab from './components/news-feed';
import NewsFormTab from './components/news-form';
import NewsUpdate from './components/news-update';
import RidesTab from './components/rides';
import WidgetsTab from './components/widgets';
import { IPromotionPageContext } from './context';

function PromotionPage(page: IDashboardPage): JSX.Element {
  // Challenges context
  const [challenges, setChallenges] = useState<Challenge[]>();
  const [selectedChallengeIndex, selectChallengeIndex] = useState<number | null>(null);

  // FMD context
  const [configurations, setConfigurations] = useState<FMDConfig[]>();
  const [selectedConfigurationIndex, selectConfigurationIndex] = useState<number>();

  // rides context
  const [rides, setRides] = useState<Ride[]>();

  // Widgets context
  const [widgets, setWidgets] = useState<WidgetConfig[]>();
  const [selectedWidgetIndex, selectWidgetIndex] = useState<number | null>(null);

  return (
    <PageContainerLayout<IPromotionPageContext>
      context={{
        challenges: {
          list: challenges,
          selectedIndex: selectedChallengeIndex,
          selectIndex: selectChallengeIndex,
          setList: setChallenges,
        },
        fmd: {
          configurations,
          selectedIndex: selectedConfigurationIndex,
          selectIndex: selectConfigurationIndex,
          setConfigurations,
        },
        rides: {
          list: rides,
          setList: setRides,
        },
        widgets: {
          list: widgets,
          selectedIndex: selectedWidgetIndex,
          selectIndex: selectWidgetIndex,
          setList: setWidgets,
        },
      }}
      page={page}
    />
  );
}

export {
  AnimationTab,
  ChallengeDetails,
  ChallengesTab,
  ChallengesFormTab,
  ChallengeUpdate,
  HomeWorkJourneysTab,
  MembersTab,
  NewsFeedTab,
  RidesTab,
  WidgetsTab,
  NewsFormTab,
  NewsUpdate,
};
export default PromotionPage;
