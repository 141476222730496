import { TPeriod, lastYear, prevMonth } from '@geovelo-frontends/commons';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import TabIntroduction from '../../../../components/tab-introduction';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';
import useAccidentology from '../../hooks/accidentology';
import { TH3CellData } from '../../models/accidentology';

import Distribution from './distribution';
import InfoDialog from './info-dialog';
import AccidentZonesRanking, { sortData } from './ranking';

function AccidentologyForm(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    period,
    accidentology: { accidentsYears, blackSpotsYears, selectedH3Index, selectH3Index },
  } = context;
  const [data, setData] = useState<TH3CellData[]>();
  const [infoDialogOpen, openInfoDialog] = useState(false);
  const incidentsAutomaticallyShowedRef = useRef(false);
  const {
    map: { incidentsShowed },
    partner: { current: currentPartner },
    actions: { toggleIncidents },
  } = useContext(AppContext);
  const { getPeriods } = useQueryParams();
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods: getPeriods(lastYear), enabledTypes: ['year', 'month', 'custom'] });
  const initializedRef = useRef(false);
  const { t } = useTranslation();
  const { h3Map, data: _data, filteredAccidentsFeatures } = useAccidentology(context);

  useEffect(() => {
    if (!initializedRef.current) {
      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;
  }, []);

  useEffect(() => {
    if (currentPartner && h3Map && _data) setData(sortData(h3Map, _data));

    return () => setData(undefined);
  }, [h3Map, _data]);

  useEffect(() => {
    if (selectedH3Index) {
      if (
        selectedH3Index &&
        !incidentsShowed.blackSpots &&
        !incidentsShowed.reports &&
        !incidentsShowed.suddenBrakings &&
        !Object.values(incidentsShowed.accidents).includes(true)
      ) {
        toggleIncidents({
          accidents: { deadly: true, hospitalized: true, injured: true },
          blackSpots: true,
          reports: true,
          suddenBrakings: true,
        });
        incidentsAutomaticallyShowedRef.current = true;
      }
    } else {
      if (incidentsAutomaticallyShowedRef.current) {
        toggleIncidents({
          accidents: { deadly: false, hospitalized: false, injured: false },
          blackSpots: false,
          reports: false,
          suddenBrakings: false,
        });
      }
      incidentsAutomaticallyShowedRef.current = false;
    }
  }, [selectedH3Index]);

  const currentYear = period.values.current.from.get('year');
  const noAccidentsData = accidentsYears && !accidentsYears.includes(currentYear);
  const noBlackSpotsData = blackSpotsYears && !blackSpotsYears.includes(currentYear);

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100%">
        <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.accidentology" />
        <Box display="flex" flexDirection="column" gap={3} marginTop={3}>
          <Card elevation={0} sx={{ borderRadius: 4 }}>
            <CardContent sx={{ '&&': { paddingX: 3, paddingY: 2 } }}>
              <PeriodForm
                disableComparison
                disablePadding
                customPeriodTypes={customPeriodTypes}
                {...period}
              />
            </CardContent>
          </Card>
          {(noAccidentsData || noBlackSpotsData) && (
            <Card elevation={0} sx={{ bgcolor: '#C9E9F8BF', borderRadius: 4 }}>
              <CardContent
                sx={{
                  '&&': {
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    paddingBottom: 2,
                  },
                }}
              >
                <InfoOutlined color="primary" />
                <Box display="flex" flexDirection="column">
                  {noAccidentsData && (
                    <Typography color="primary" variant="caption">
                      {t('cycling-insights.usage.accidentology.accidents.empty_state', {
                        year: currentYear,
                        years: accidentsYears.join(', '),
                      })}
                    </Typography>
                  )}
                  {noBlackSpotsData && (
                    <Typography color="primary" variant="caption">
                      {t('cycling-insights.usage.accidentology.black_spots.empty_state', {
                        year: currentYear,
                        years: blackSpotsYears.join(', '),
                      })}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          )}
          <Card elevation={0} sx={{ borderRadius: 4 }}>
            <CardContent
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, '&&': { padding: 3 } }}
            >
              <Card sx={{ borderRadius: 4 }} variant="outlined">
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1, '&&': { padding: 2 } }}
                >
                  <Box alignItems="center" display="flex" gap={1}>
                    <Typography color="textSecondary" variant="body2">
                      {t('cycling-insights.usage.accidentology.accident_zones.title')}
                    </Typography>
                    <IconButton onClick={() => openInfoDialog(true)} size="small">
                      <InfoOutlined color="action" fontSize="small" />
                    </IconButton>
                  </Box>
                  <Typography variant="h5">
                    {data ? data.length : <Skeleton variant="text" width={100} />}
                  </Typography>
                </CardContent>
              </Card>
              <Box marginX={-3}>
                <AccidentZonesRanking
                  enableTitle
                  data={data?.slice(0, 3)}
                  h3Map={h3Map}
                  period={period.values.current}
                  selectH3Index={selectH3Index}
                />
              </Box>
            </CardContent>
          </Card>
          {!noAccidentsData &&
            (filteredAccidentsFeatures && filteredAccidentsFeatures.length === 0 ? (
              <Card elevation={0} sx={{ borderRadius: 4 }}>
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: 3, '&&': { padding: 3 } }}
                >
                  <Typography fontSize="1.125rem" fontWeight={700}>
                    {t('cycling-insights.usage.accidentology.distribution.title')}
                  </Typography>
                  <Typography>{t('commons.no_filtered_data')}</Typography>
                </CardContent>
              </Card>
            ) : (
              <Distribution features={filteredAccidentsFeatures} />
            ))}
        </Box>
      </Box>
      <InfoDialog onClose={() => openInfoDialog(false)} open={infoDialogOpen} />
    </>
  );
}

export default AccidentologyForm;
