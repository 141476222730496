import {
  SectionStats,
  StatsService,
  currentYear,
  prevMonth,
  roadTypesLabels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { InfoOutlined, Place } from '@mui/icons-material';
import { Box, ButtonBase, Paper, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useCyclability from '../../../../hooks/map/cyclability';
import useQueryParams from '../../../../hooks/query-params';
import { TOutletContext } from '../../../../layouts/page/container';
import {
  getFilteredSections as _getFilteredSections,
  splitRoadsMinZoom,
} from '../../../../utils/sections';
import { TCartographicDataPageContext } from '../../context';

import CyclabilityChart from './chart';
import CyclabilityDialog from './dialog';

function CyclabilityForm(context: TCartographicDataPageContext & TOutletContext): JSX.Element {
  const {
    header: { setTitle },
    period,
    cyclability: {
      data,
      bounds,
      currentRange,
      secondaryBounds,
      setData,
      setBounds,
      setCurrentRange,
      setRoadsCountByCyclability,
      setSecondaryBounds,
    },
    setLoading,
  } = context;
  const [stats, setStats] = useState<{
    [key: number]: { current?: SectionStats; prev?: SectionStats };
  }>();
  const [dialogOpen, openDialog] = useState<boolean>(false);
  const {
    map: { current: currentMap, zoom },
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    cancellablePromise: cancellableCyclabilityDistributionPromise,
    cancelPromises: cancelCyclabilityDistributionPromise,
  } = useCancellablePromise();
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useCyclability(currentMap);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const previousZoom = useRef<number>();
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    if (!initializedRef.current) {
      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;

    setTitle(
      <Box alignItems="center" display="flex" gap={1}>
        <Trans i18nKey="cycling-insights.bicycle_observatory.navigation.cyclability" />
        <ButtonBase onClick={() => openDialog(true)}>
          <InfoOutlined fontSize="small" sx={{ color: '#5D687E' }} />
        </ButtonBase>
      </Box>,
    );

    return () => {
      setTitle(undefined);
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none') getStats();

    return () => {
      cancelPromises();
      setData(undefined);
      setBounds(undefined);
      setCurrentRange(undefined);
      setSecondaryBounds(undefined);
      setLoading(false);

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentMap) {
      initLayers();
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (zoom === undefined) return;
    if (
      (zoom >= splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current < splitRoadsMinZoom) ||
      (zoom < splitRoadsMinZoom &&
        previousZoom.current &&
        previousZoom.current >= splitRoadsMinZoom)
    )
      getFilteredSections();
    previousZoom.current = zoom;
  }, [zoom]);

  useEffect(() => {
    if (sections) getCyclabilityDistribution();

    return () => {
      cancelCyclabilityDistributionPromise();
      setRoadsCountByCyclability(undefined);
    };
  }, [sections]);

  useEffect(() => {
    if (layersInitialized) getFilteredSections();
    setLoading(
      !sections || (!stats && currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none'),
    );
  }, [layersInitialized, sections, stats]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        updateLayers(data, { currentRange, primaryBounds: bounds, secondaryBounds });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds: undefined,
        },
      );
    }
  }, [layersInitialized, data, currentRange]);

  async function getStats() {
    cancelPromises();
    setData(undefined);
    setBounds(undefined);
    setCurrentRange(undefined);
    setStats(undefined);

    if (!currentPartner) return;

    try {
      const frequencyStats = await cancellablePromise(
        StatsService.getSectionsSpeedsAndFrequencies(currentPartner.id, currentYear.toIPeriod(), {
          timePeriod: 'all_day',
          dayPeriod: 'all',
        }),
      );

      const _stats: { [key: number]: { current?: SectionStats; prev?: SectionStats } } = {};
      frequencyStats?.forEach((element) => {
        const sectionStats: SectionStats = {
          sectionId: element.sectionId,
          frequencyBackward: element.frequencyBackward,
          frequencyOnward: element.frequencyOnward,
        };
        _stats[element.sectionId] = { current: sectionStats };
      });

      setStats(_stats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });

        setBounds({ min: 0, max: 4 });
        setData(null);
      }
    }
  }

  async function getCyclabilityDistribution() {
    if (!currentPartner) return;

    try {
      const { good, very_good, bad, very_bad } = await cancellableCyclabilityDistributionPromise(
        StatsService.getCyclabilityDistribution(currentPartner.id),
      );
      setRoadsCountByCyclability([very_bad, bad, good, very_good]);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });
      }
    }
  }

  function getFilteredSections() {
    if (
      !sections ||
      (!stats && currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none') ||
      zoom === undefined
    )
      return;

    const {
      filteredSections,
      bounds: _bounds,
      currentRange: _currentRange,
      secondaryBounds: _secondaryBounds,
    } = _getFilteredSections({
      maxBounds: 4,
      defaultMinRange: 0,
      sections,
      period,
      zoom,
      stats,
      primaryCriterion: 'cyclability',
      bounds,
      currentRange,
    });

    setData(filteredSections);
    setBounds(_bounds);
    setCurrentRange(_currentRange);
    setSecondaryBounds(_secondaryBounds);
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
        <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.cyclability" />

        <Paper
          elevation={0}
          sx={{ borderRadius: 4, display: 'flex', flexDirection: 'column', padding: 3 }}
        >
          <CyclabilityChart {...context} />
        </Paper>

        {currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'none' && (
          <Ranking
            disableProgression
            action={
              <Tooltip
                title={<Trans i18nKey="cycling-insights.facilities.cyclability.ranking.tooltip" />}
              >
                <InfoOutlined fontSize="small" />
              </Tooltip>
            }
            data={data?.features
              .sort((a, b) =>
                zoom && zoom >= splitRoadsMinZoom
                  ? (b.properties.sectionFrequency || 0) - (a.properties.sectionFrequency || 0)
                  : (b.properties.frequency || 0) - (a.properties.frequency || 0),
              )
              .filter(
                ({ id, properties: { cyclability } }) =>
                  cyclability &&
                  cyclability === 1 &&
                  (typeof id === 'number' ||
                    (typeof id === 'string' &&
                      (id.indexOf('-') < 0 ||
                        (id.indexOf('-0') >= 0 && id.indexOf('backward') < 0)))),
              )
              .slice(0, 5)
              .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
                (
                  res,
                  {
                    properties: {
                      id: propertiesId,
                      wayName,
                      frequency,
                      sectionFrequency,
                      roadType,
                    },
                  },
                ) => {
                  if (zoom && zoom >= splitRoadsMinZoom) {
                    res.push({
                      id: propertiesId,
                      title:
                        wayName ||
                        t(roadTypesLabels[roadType || 'unclassified']) +
                          ' - ' +
                          propertiesId.toString(),
                      value: sectionFrequency || 0,
                    });
                    return res;
                  } else {
                    res.push({
                      id: propertiesId,
                      title:
                        wayName ||
                        t(roadTypesLabels[roadType || 'unclassified']) +
                          ' - ' +
                          propertiesId.toString(),
                      value: frequency || 0,
                    });
                    return res;
                  }
                },
                [],
              )}
            formatData={({ value }) => (
              <Typography fontWeight={600} minWidth={50} textAlign="end" variant="body2">
                <Trans count={value} i18nKey="commons.stats.passages" values={{ count: value }} />
              </Typography>
            )}
            onClick={(id) => {
              const feature = data?.features.find(({ properties }) => properties.id === id);
              const coordinates = feature?.geometry.coordinates;

              if (coordinates) {
                const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
                currentMap?.flyTo({
                  center: { lat, lng },
                  zoom: 18,
                  animate: false,
                });
              }
            }}
            startIcon={<Place sx={{ color: '#E76685' }} />}
            title="cycling-insights.facilities.cyclability.ranking.title"
          />
        )}
      </Box>
      <CyclabilityDialog onClose={() => openDialog(false)} open={dialogOpen} />
    </>
  );
}

export default CyclabilityForm;
