import {
  GeogroupAvatar,
  PartnerService,
  currentYear,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Etiquette } from '@incubateur-ademe/impactco2-react';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import StatCard from '../../../../components/stat-card';
import TabIntroduction from '../../../../components/tab-introduction';
import { environment } from '../../../../environment';
import useQueryParams from '../../../../hooks/query-params';

import CompaniesDialog, { TItem, sizeRanges } from './companies-dialog';

type TStats = { activeMembersCount: number; distance: number; tracesCount: number };

function Content(): JSX.Element {
  const { getPeriods } = useQueryParams();
  const [initialized, setInitialized] = useState(false);
  const [defaultPeriods] = useState(() => getPeriods(currentYear.clone()));
  const [periods, setPeriods] = useState<IPeriodFormValues>(defaultPeriods);
  const [companiesInArea, setCompaniesInArea] = useState<TItem[]>();
  const [sizeRangeIndex, setSizeRangeIndex] = useState(-1);
  const [items, setItems] = useState<TItem[]>();
  const [stats, setStats] = useState<{ current: TStats; prev: TStats }>();
  const [companiesDialogOpen, toggleCompaniesDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { formatNumber } = useUnits();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) getCompaniesInArea();

    return () => {
      cancelPromises();
      setCompaniesInArea(undefined);
    };
  }, [initialized, currentPartner, periods]);

  useEffect(() => {
    const { min, max } =
      sizeRangeIndex > -1 ? sizeRanges[sizeRangeIndex] : { min: undefined, max: undefined };
    setItems(
      companiesInArea
        ?.filter(
          ({ value }) => (min === undefined || min <= value) && (max === undefined || max >= value),
        )
        .sort((a, b) => b.value - a.value)
        .slice(0, 5),
    );
  }, [companiesInArea, sizeRangeIndex]);

  async function getCompaniesInArea() {
    if (!currentPartner) return;

    try {
      const [companiesInArea, prevCompaniesInArea] = await cancellablePromise(
        Promise.all([
          PartnerService.getCompaniesInArea({ partner: currentPartner, period: periods.current }),
          PartnerService.getCompaniesInArea({
            partner: currentPartner,
            period: periods.prev,
          }),
        ]),
      );

      setStats({
        current: companiesInArea.reduce<TStats>(
          (res, { homeWorkActiveMembersCount, homeWorkDistance, homeWorkTracesCount }) => {
            res.activeMembersCount += homeWorkActiveMembersCount;
            res.distance += homeWorkDistance;
            res.tracesCount += homeWorkTracesCount;
            return res;
          },
          { activeMembersCount: 0, distance: 0, tracesCount: 0 },
        ),
        prev: prevCompaniesInArea.reduce<TStats>(
          (res, { homeWorkActiveMembersCount, homeWorkDistance, homeWorkTracesCount }) => {
            res.activeMembersCount += homeWorkActiveMembersCount;
            res.distance += homeWorkDistance;
            res.tracesCount += homeWorkTracesCount;
            return res;
          },
          { activeMembersCount: 0, distance: 0, tracesCount: 0 },
        ),
      });

      setCompaniesInArea(
        companiesInArea.map(({ title: _title, parentTitle, photo, membersCount }, index) => {
          const title = parentTitle || _title;

          return {
            id: `${title}_${index}`,
            startIcon: <GeogroupAvatar geogroup={{ title, photo }} />,
            title,
            value: membersCount,
          };
        }),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.community.home_work_journeys.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <TabIntroduction title="cycling-insights.community.introduction.home_work_journeys" />
        <Box display="flex" flexDirection="column" flexShrink={0} gap={3} marginTop={3}>
          <Paper
            header={
              <PeriodForm
                disableComparison
                disablePadding
                disablePeriodTypeChange
                customPeriodTypes={{ defaultPeriods, enabledTypes: ['year'] }}
                setValues={setPeriods}
                values={periods}
              />
            }
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" flexDirection="row" gap={2}>
                <Box width="calc((100% - 16px) / 2)">
                  <StatCard
                    format={formatNumber}
                    label={t('cycling-insights.community.home_work_journeys.traces_count_label')}
                    prevPeriod={periods.prev}
                    prevValue={stats?.prev.tracesCount}
                    value={stats?.current.tracesCount}
                  />
                </Box>
                <Box width="calc((100% - 16px) / 2)">
                  <StatCard
                    format={formatNumber}
                    label={t('cycling-insights.community.home_work_journeys.active_employees')}
                    prevPeriod={periods.prev}
                    prevValue={stats?.prev.activeMembersCount}
                    value={stats?.current.activeMembersCount}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  '&& > div > div > div': {
                    width: '100% !important',
                    '> div:nth-child(2)': { flexGrow: 1 },
                  },
                }}
              >
                {stats ? (
                  <Etiquette
                    animated
                    comparisons={['tgv-paris-marseille', 'voitureelectrique', 'cafe']}
                    language="fr"
                    value={
                      Math.round(
                        (stats.current.distance / 1000) *
                          environment.savedKgOfCO2PerKilometer *
                          10_000,
                      ) / 10
                    }
                  />
                ) : (
                  <Skeleton height={80} variant="rounded" width="100%" />
                )}
              </Box>
            </Box>
          </Paper>
          <Paper
            disablePadding
            header={
              <Box alignItems="center" display="flex" gap={2} justifyContent="space-between">
                <Typography fontSize="1.125rem" fontWeight={700}>
                  {t('cycling-insights.community.home_work_journeys.companies_list.title')}
                </Typography>
                {companiesInArea && companiesInArea.length > 0 && (
                  <Button
                    endIcon={<KeyboardArrowRight />}
                    onClick={() => toggleCompaniesDialog(true)}
                    variant="text"
                  >
                    {t('cycling-insights.community.home_work_journeys.companies_list.actions.all', {
                      count: companiesInArea.length,
                    })}
                  </Button>
                )}
              </Box>
            }
          >
            <Box display="flex" flexDirection="column">
              <Box padding={2}>
                <FormControl margin="none" size="small">
                  <Select
                    onChange={({ target: { value } }) => {
                      setSizeRangeIndex(typeof value === 'string' ? parseInt(value) : value);
                    }}
                    renderValue={(selected) => {
                      if (selected === -1)
                        return (
                          <Typography color="#b6b6b6" component="span" fontSize="inherit">
                            {t(
                              'cycling-insights.community.home_work_journeys.companies_list.all_sizes',
                            )}
                          </Typography>
                        );

                      const { labelKey, min, max } = sizeRanges[selected];
                      return t(labelKey, { min, max });
                    }}
                    style={{ width: 300 }}
                    value={sizeRangeIndex}
                  >
                    <MenuItem value={-1}>
                      {t('cycling-insights.community.home_work_journeys.companies_list.all_sizes')}
                    </MenuItem>
                    {sizeRanges.map(({ labelKey, min, max }, index) => (
                      <MenuItem key={index} value={index}>
                        {t(labelKey, { min, max })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Ranking
                disableProgression
                data={items}
                formatData={({ value }) => (
                  <Typography minWidth={80} textAlign="end" variant="body2">
                    {t('cycling-insights.community.home_work_journeys.companies_list.employees', {
                      count: value,
                    })}
                  </Typography>
                )}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <CompaniesDialog
        companiesInArea={companiesInArea}
        dialogTitle="companies-dialog"
        onClose={() => toggleCompaniesDialog(false)}
        open={companiesDialogOpen}
      />
    </>
  );
}

export default Content;
